// footer big menu
.footer-big-menu {
  /*margin-top: 120px;*/
  padding: 80px 0 130px;
  background: #2B4255;
  position: relative;

  .cta {
    .cta-title {
      color: #fff;
      font-size: 1.6rem;
      margin-bottom: 40px;
      text-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);

      @media(max-width: 767px) {
        font-size: 1.3rem;
      }
    }

    a {
      font-size: 12px;
    }

    .btn-outline {
      text-transform: uppercase;
      border: 2px solid #91bbe0;
      font-weight: 600;
      padding: 12px 23px;
      color: #b5dcff;
      border-radius: 0.25rem;
      letter-spacing: 0.5px;
      display: inline-block;

      @include transition(all .2s);

      &:hover {
        border-color: #fff;
        color: #fff;
      }

      @media (max-width: 575px) {
        margin-top: 20px;
      }
    }
  }

  .menu {
    margin-top: 100px;

    @media (max-width: 767px) {
      margin-top: 60px;
      text-align: center;
    }

    .col-md-3 {
      @media(max-width: 991px) {
        margin-bottom: 30px;
      }
    }

    .menu-title {
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.5px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .menu-link {
      color: #97b0c5;
      display: block;
      margin-bottom: 2px;

      @include transition(all .2s);

      &:hover {
        color: #fff;
      }
    }

    .social-link {
      width: 32px;
      height: 32px;
      display: inline-block;
      position: relative;
      border-radius: 30px;
      color: #dfebf5;
      border: 2px solid #c6d8e8;
      margin-right: 6px;

      @include transition(all .2s);

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: #fff;
        border-color: #fff;
      }

      i {
        position: absolute;
        font-size: 16px;
        left: 8px;
        top: 2px;

        &.ion-social-facebook {
          left: 11px;
        }

        &.ion-social-whatsapp {
          left: 7px;
        }
      }
    }
  }

  .bottom {
    color: #68849a;
    position: absolute;
    bottom: 18px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 13px;

    a {
      color: #68849a;
      margin-left: 5px;
      display: inline-block;

      @include transition(all .2s);

      &:hover {
        color: #fff;
      }
    }
  }
}

// footer sign up
.footer-signup {
  margin-top: 100px;
  background: #334054;
  color: #fff;
  padding: 55px 0 110px;
  text-align: center;

  h3 {
    font-weight: 400;
    font-size: 27px;

    @media(max-width: 767px) {
      font-size: 25px;
    }
  }

  p {
    font-size: 19px;
    color: #9AADC7;
    margin-top: 13px;

    @media(max-width: 767px) {
      font-size: 16px;
    }
  }

  form {
    width: 470px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 50px;
    border: 1px solid #334054;
    border-radius: 4px;
    background: #4B596E;

    @media(max-width: 767px) {
      width: auto;
      padding: 25px;
    }

    input[type="email"],
    input[type="password"] {
      height: 46px;
      font-size: 15px;

      @include placeholder {
        color: #999;
      }
    }

    [type="submit"] {
      margin-top: 20px;
      display: block;
      width: 100%;
      padding: 14px 12px;
      font-weight: 500;
      border: 0;
      font-size: 16px;
      text-shadow: 1px 1px rgba(0, 0, 0, 0.08);
      background-color: #4AB2DF;
      outline: none;
      cursor: pointer;

      @include transition(all .2s linear);

      &:active {
        outline: none;
      }
    }
  }
}

// footer light
.footer-light {
  padding: 40px 0;
  text-align: center;
  background: #212A38;
  font-size: 16px;

  span {
    color: #AEBDD3;
    margin: 0 55px;
  }

  a {
    color: #AEBDD3;
    text-decoration: none;
    margin: 0 15px;

    @media(max-width: 767px) {
      display: block;
      margin-top: 20px;
    }
  }
}
